import { queryVariety } from '@/api/wms/house/house'
import { listVariety } from '@/api/wms/house/variety'
import { getInfo } from '@/api/login'
import { onBeforeMount, ref } from 'vue'
import store from '@/store/index'

export function useVarieties(cb) {
  const varietyList = ref([])
  const onlyHasCF = ref(false)

  function hasVarietyCode(code) {
    return varietyList.value.findIndex((item) => item.value === code) >= 0
  }

  async function getAdminVarietyList() {
    const res = await listVariety()
    varietyList.value = (res.data || [])
      .filter((item) => item.pvCode != '0')
      .map((item) => {
        return {
          value: item.vCode,
          label: item.vName,
          itemObj: { ...item, storageBasis: '1' }
        }
      })
    onlyHasCF.value =
      varietyList.value.length === 1 && varietyList.value[0].value === 'CF'
    if (cb) cb()
  }

  async function getVarietyList() {
    const res = await queryVariety()

    varietyList.value = res.data.map((item) => {
      return {
        value: item.varietyCode,
        label: item.varietyName,
        itemObj: item
      }
    })
    onlyHasCF.value =
      varietyList.value.length === 1 && varietyList.value[0].value === 'CF'
    if (cb) cb()
  }

  function requestVarietyList(roles) {
    if (roles.indexOf('admin') >= 0) {
      getAdminVarietyList()
    } else {
      getVarietyList()
    }
  }

  onBeforeMount(() => {
    if (store.getters.roles.length === 0) {
      getInfo().then((res) => {
        requestVarietyList(res.roles)
      })
    } else {
      requestVarietyList(store.getters.roles)
    }
  })

  return {
    onlyHasCF,
    varietyList,
    hasVarietyCode
  }
}
