<template>
  <div
    :class="{ 'has-logo': showLogo }"
    :style="{
      backgroundColor:
        sideTheme === 'theme-dark'
          ? variables.menuBackground
          : variables.menuLightBackground
    }"
  >
    <logo v-if="showLogo" :collapse="isCollapse" style="padding-right: 42px" />
    <el-scrollbar
      :class="sideTheme"
      wrap-class="scrollbar-wrapper"
      style="
        --el-scrollbar-bg-color: transparent;
        --el-scrollbar-hover-bg-color: transparent;
      "
    >
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="
          sideTheme === 'theme-dark'
            ? variables.menuBackground
            : variables.menuLightBackground
        "
        :text-color="
          sideTheme === 'theme-dark'
            ? variables.menuColor
            : variables.menuLightColor
        "
        :unique-opened="true"
        :active-text-color="theme"
        :collapse-transition="false"
        mode="vertical"
        style="--el-font-size-base: 14px"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <div
      :class="['show-container', isCollapse ? 'hide' : '']"
      @click="toggleSideBar"
    >
      <div class="circle">
        <!-- <el-icon style="color: white; font-size: 20px">
          <arrow-left-bold v-if="!isCollapse"></arrow-left-bold>
          <arrow-right-bold v-else></arrow-right-bold>
        </el-icon> -->
        <svg-icon icon-class="retract"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.module.scss'
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'

const route = useRoute()
const store = useStore()

const sidebarRouters = computed(() => store.getters.sidebarRouters)
const showLogo = computed(() => store.state.settings.sidebarLogo)
const sideTheme = computed(() => store.state.settings.sideTheme)
const theme = computed(() => store.state.settings.theme)
const isCollapse = computed(() => !store.state.app.sidebar.opened)

const activeMenu = computed(() => {
  const { meta, path } = route
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})

function toggleSideBar() {
  store.dispatch('app/toggleSideBar')
}
</script>

<style lang="scss" scoped>
.show-container {
  position: fixed;
  left: 310px;
  top: 43px;
  // background: url('/static/images/mask.png');
  // width: 42px;
  // height: 123px;
  transition: left 0.28s;
  &.hide {
    left: 152px;
  }
  .circle {
    position: relative;
    // width: 40px;
    // height: 40px;
    // background-color: #51A8FF;
    // border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // left: 12px;
    // top: calc(50% - 20px);
    cursor: pointer;
    font-size: 24px;
  }
}
</style>
