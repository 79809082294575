<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <div class="pagination-label">
      显示第<span class="num">{{ (page - 1) * pageSize + 1 }} </span>到第<span
        class="num"
        >{{ total > page * pageSize ? page * pageSize : total }} </span
      >条记录，总共<span class="num">{{ total }} </span>条记录，每页显示<span
        class="num"
      >
        <el-select
          class="size-choose"
          v-model="pageSize"
          @change="handleSizeChange"
        >
          <el-option
            v-for="item in pageSizes"
            :value="item"
            :label="item"
          ></el-option>
        </el-select> </span
      >条记录
    </div>

    <el-pagination
      class="page"
      :background="background"
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :layout="layout"
      :pager-count="pagerCount"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script setup>
import { scrollTo } from '@/utils/scroll-to'

const props = defineProps({
  total: {
    required: true,
    type: Number
  },
  page: {
    type: Number,
    default: 1
  },
  limit: {
    type: Number,
    default: 20
  },
  pageSizes: {
    type: Array,
    default() {
      return [10, 20, 50, 100, 200, 500]
    }
  },
  // 移动端页码按钮的数量端默认值5
  pagerCount: {
    type: Number,
    default: document.body.clientWidth < 992 ? 5 : 7
  },
  layout: {
    type: String,
    default: ' prev, pager, next, jumper'
  },
  background: {
    type: Boolean,
    default: true
  },
  autoScroll: {
    type: Boolean,
    default: true
  },
  hidden: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits()
const currentPage = computed({
  get() {
    return props.page
  },
  set(val) {
    emit('update:page', val)
  }
})
const pageSize = computed({
  get() {
    return props.limit
  },
  set(val) {
    emit('update:limit', val)
  }
})
function handleSizeChange(val) {
  if (currentPage.value * val > props.total) {
    currentPage.value = 1
  }
  emit('pagination', { page: currentPage.value, limit: val })
  if (props.autoScroll) {
    scrollTo(0, 800)
  }
}
function handleCurrentChange(val) {
  emit('pagination', { page: val, limit: pageSize.value })
  if (props.autoScroll) {
    scrollTo(0, 800)
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 14px;
  .pagination-label {
    height: 19px;
    line-height: 19px;
    .num {
      color: #333;
      text-align: center;
    }
  }
}
.pagination-container.hidden {
  display: none;
}

.size-choose {
  width: 60px;
  --el-select-font-size: 14px;
  --el-select-input-font-size: 14px;
  --el-border-color: #e7e7e7;
}

.page {
  --el-color-primary: #c1c1c1;
}
</style>
