import { getDicts } from '@/api/system/dict/data'

const cacheDicts = new Map()

/**
 * 获取字典数据
 */
export function useDict(...args) {
  const res = ref({})
  return (() => {
    args.forEach((d, index) => {
      res.value[d] = []
      if (cacheDicts.has(d)) {
        res.value[d] = cacheDicts.get(d)
        return
      }
      getDicts(d).then((resp) => {
        res.value[d] = resp.data.map((p) => ({
          label: p.dictLabel,
          value: p.dictValue,
          elTagType: p.listClass
        }))
        cacheDicts.set(d, res.value[d])
      })
    })
    return toRefs(res.value)
  })()
}

export function dictName(value, dicts) {
  if (!value || !dicts) return ''

  const item = dicts.find((it) => {
    return it.value == value
  })
  return item ? item.label : value
}
