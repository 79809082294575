<template>
  <svg-icon icon-class="xj" @click="goto"></svg-icon>
</template>

<script setup>
const url = ref('http://8.142.146.190:5050/GuardTour/Login.jsp')

function goto() {
  window.open(url.value)
}
</script>
