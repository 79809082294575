import request from '@/utils/request'
import store from '@/store'

// 查询仓库（租户）列表
export function listHouse(query) {
  return request({
    url: '/wms/house/list',
    method: 'get',
    params: query
  })
}

// 查询仓库（租户）详细
export function getHouse(tenantId) {
  return request({
    url: '/wms/house/' + tenantId,
    method: 'get'
  })
}

// 查询租户仓库支持的品种
export function queryVariety() {
  return request({
    url: '/wms/house/queryVariety',
    method: 'get'
  })
}

// 新增仓库（租户）
export function addHouse(data) {
  return request({
    url: '/wms/house',
    method: 'post',
    data: data
  })
}

// 修改仓库（租户）
export function updateHouse(data) {
  return request({
    url: '/wms/house',
    method: 'put',
    data: data
  })
}

// 修改仓库资料（租户）
export function updateHouseInfo(data) {
  return request({
    url: '/wms/house/info',
    method: 'put',
    data: data
  })
}

// 修改仓库（租户）
export function updateVarietyRel(data) {
  return request({
    url: '/wms/house/updateVarietyRel',
    method: 'put',
    data: data
  })
}

// 更新三维状态
export function openOrCloseThree(data) {
  return request({
    url: '/wms/house/openOrCloseThree',
    method: 'post',
    data: data
  })
}

// 删除仓库（租户）
export function delHouse(tenantId) {
  return request({
    url: '/wms/house/' + tenantId,
    method: 'delete'
  })
}


// 租户查询仓库详细
export function getHouseInfo() {
  const tenantId = store.getters && store.getters.tenantId
  return request({
    url: '/wms/house/' + tenantId,
    method: 'get'
  })
}

// 查询品种下拉树结构
export function treeselect() {
  return request({
    url: '/wms/house/variety/treeselect',
    method: 'get'
  })
}


// 仓库容器库存统计
export function capacityStatistics() {
  return request({
    url: '/wms/house/capacityStatistics',
    method: 'get'
  })
}


// 仓库容器库存统计
export function wareHouseStatistics(query) {
  return request({
    url: '/wms/house/wareHouseStatistics',
    method: 'get',
    params: query
  })
}

//查询所有区域
export function listAllArea(query) {
  return request({
    url: '/wms/area/listAll',
    method: 'get',
    params: query
  })
}

//查询某个库区下所有库房
export function listAllRoom(query) {
  return request({
    url: '/wms/room/listAll',
    method: 'get',
    params: query
  })
}


//查询某个库房下所有垛位
export function listAllStack(query) {
  return request({
    url: '/wms/stack/listAll',
    method: 'get',
    params: query
  })
}

// 仓库状态修改
export function changeWareHouseStatus(data) {
  return request({
    url: '/wms/house/changeStatus',
    method: 'put',
    data: data
  })
}