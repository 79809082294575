<template>
  <div class="navbar">
    <!-- <hamburger
      id="hamburger-container"
      :is-active="getters.sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    /> -->
    <!-- <breadcrumb
      id="breadcrumb-container"
      class="breadcrumb-container"
      v-if="!$store.state.settings.topNav"
    /> -->
    <top-nav
      id="topmenu-container"
      class="topmenu-container"
      v-if="$store.state.settings.topNav"
    />

    <div class="right-menu">
      <div style="display: flex; margin-right: 20px">
        <div
          style="font-size: 15px; color: rgb(96, 98, 102); font-weight: bold"
        >
          品种：
        </div>
        <div style="width: 150px">
          <el-select
            class="breed"
            style="
              <!-- --el-fill-color-blank: rgba(222, 222, 222, 0.2); -->
              --el-border-color: rgb(222, 222, 222);
              --el-text-color-regular: #333;
            "
            placeholder="请选择品种"
            v-model="store.state.variety.varietyId"
            @change="onVarietyIdChange"
            ><el-option
              v-for="item in varietyList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </div>
      </div>

      <template v-if="getters.device !== 'mobile'">
        <el-tooltip content="巡检系统" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect">
            <WmsPatrol id="wmsPatrol" />
          </div>
        </el-tooltip>
        <el-tooltip content="三维视图" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect">
            <svg-icon icon-class="view3d" @click="toPage"></svg-icon>
          </div>
        </el-tooltip>
        <el-tooltip content="SdnDemo" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect">
            <svg-icon @click="toSdnPage" icon-class="sdn"></svg-icon>
          </div>
        </el-tooltip>
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
      </template>
      <div class="avatar-container">
        <el-dropdown
          @command="handleCommand"
          class="right-menu-item hover-effect"
          trigger="click"
        >
          <div class="avatar-wrapper">
            <img :src="getters.avatar" class="user-avatar" />
            <el-icon><caret-bottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item command="setLayout">
                <span>布局设置</span>
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessageBox } from 'element-plus'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import WmsPatrol from '@/components/Wms/Patrol'
import { useVarieties } from '@/hooks/index'

const store = useStore()
const getters = computed(() => store.getters)

const { varietyList } = useVarieties(() => {
  store.commit('variety/setVarietyList', varietyList.value)
  const idx = varietyList.value.findIndex((item) => {
    return item.value === store.state.variety.varietyId
  })

  if (store.state.variety.varietyId === '' || idx < 0) {
    store.commit('variety/changeVarietyId', varietyList.value[0].value)
  }
})

function onVarietyIdChange(varietyId) {
  store.commit('variety/changeVarietyId', varietyId)
}

function toggleSideBar() {
  store.dispatch('app/toggleSideBar')
}

function toPage(e) {
  window.location.href = '/3d/cf/index.html'
  // let warehouse = JSON.parse(localStorage.getItem('warehouse')) || '{}';
  // if(warehouse.variety.includes("CF_|_棉花")) {
  //   //proxy.$router.push({ path: "/3d/cf/index.html"});
  //   window.location.href = "/3d/cf/index.html";
  // } else if(warehouse.variety.includes("TA_|_PTA")) {
  //   //proxy.$router.push({ path: "/3d/ta/index.html"});
  //   window.location.href = "/3d/ta/index.html";
  // }
}

function toSdnPage(e) {
  window.location.href = '/sdn/index.html'
}

function handleCommand(command) {
  switch (command) {
    case 'setLayout':
      setLayout()
      break
    case 'logout':
      logout()
      break
    default:
      break
  }
}

function logout() {
  ElMessageBox.confirm('确定注销并退出系统吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = '/index'
      })
    })
    .catch(() => {})
}

const emits = defineEmits(['setLayout'])
function setLayout() {
  emits('setLayout')
}
</script>

<style lang="scss" scoped>
:deep(.breed.el-select) {
  line-height: 50px;
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    background: rgba(255, 255, 255, 0.57);
  }
}
.navbar {
  height: 100px;
  padding-top: 30px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  // background: #fff;
  // border-bottom: 1px solid #dedede;
  // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0px 8px 0 8px;
      height: 100%;
      font-size: 20px;
      color: #333333;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #f6f6f6;
        }
      }
    }

    .avatar-container {
      margin-right: 20px;

      .avatar-wrapper {
        margin-top: 10px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 10px;
        }

        i {
          cursor: pointer;
          position: absolute;
          right: -14px;
          top: 23px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
