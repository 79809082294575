const _lut = []

for (let i = 0; i < 256; i++) {
  _lut[i] = (i < 16 ? '0' : '') + i.toString(16)
}

export function generateUUID() {
  const d0 = (Math.random() * 0xffffffff) | 0
  const d1 = (Math.random() * 0xffffffff) | 0
  const d2 = (Math.random() * 0xffffffff) | 0
  const d3 = (Math.random() * 0xffffffff) | 0
  const uuid =
    _lut[d0 & 0xff] +
    _lut[(d0 >> 8) & 0xff] +
    _lut[(d0 >> 16) & 0xff] +
    _lut[(d0 >> 24) & 0xff] +
    '-' +
    _lut[d1 & 0xff] +
    _lut[(d1 >> 8) & 0xff] +
    '-' +
    _lut[((d1 >> 16) & 0x0f) | 0x40] +
    _lut[(d1 >> 24) & 0xff] +
    '-' +
    _lut[(d2 & 0x3f) | 0x80] +
    _lut[(d2 >> 8) & 0xff] +
    '-' +
    _lut[(d2 >> 16) & 0xff] +
    _lut[(d2 >> 24) & 0xff] +
    _lut[d3 & 0xff] +
    _lut[(d3 >> 8) & 0xff] +
    _lut[(d3 >> 16) & 0xff] +
    _lut[(d3 >> 24) & 0xff]

  // .toLowerCase() here flattens concatenated strings to save heap memory space.
  return uuid.toLowerCase()
}

/**
 *
 * @param {number} num
 * @returns
 */
export function formatTen(num) {
  if (num >= 10) {
    return num
  } else {
    return '0' + num
  }
}

const specialChars = ['/', '\\', ':', '*', '?', '<', '>', '|']

/**
 * @param {string} fileName
 */
export function getBaseName(fileName) {
  let name = fileName
  const pos = fileName.lastIndexOf('.')
  if (pos !== -1) {
    name = fileName.substring(0, pos)
  }
  // return encodeURIComponent(name)
  //return name
  let res = ''
  for (let i = 0; i < name.length; i++) {
    if (specialChars.indexOf(name[i]) < 0) res += name[i]
  }

  return res
}

/**
 *
 * @param {string} fileName
 */
export function getExtension(fileName) {
  let extension = ''
  const pos = fileName.lastIndexOf('.')
  if (pos !== -1) {
    extension = fileName.substring(pos)
  }
  return extension
}

/**
 *
 * @param {string} fileName
 */
export function getFileKey(fileName) {
  const date = new Date()
  const dir = `${date.getFullYear()}/${formatTen(
    date.getMonth() + 1
  )}/${formatTen(date.getDate())}/`

  return (
    dir +
    getBaseName(fileName) +
    '_' +
    generateUUID() +
    '_' +
    date.getTime() +
    getExtension(fileName)
  )
}

export function openUrl(url) {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}
