import cache from '@/plugins/cache'

export default {
  namespaced: true,
  state: {
    varietyList: [],
    varietyId: cache.local.get('choose-varietyId') || '',
    itemObj: null
  },
  mutations: {
    changeVarietyId(state, varietyId) {
      state.varietyId = varietyId
      state.itemObj = state.varietyList.find(
        (item) => item.value === state.varietyId
      ).itemObj
      cache.local.set('choose-varietyId', varietyId)
    },
    setVarietyList(state, varietyList) {
      state.varietyList = varietyList
      if (state.varietyId) {
        const item = state.varietyList.find(
          (item) => item.value === state.varietyId
        )
        if (item) {
          state.itemObj = item.itemObj
        }
      }
    }
  }
}
